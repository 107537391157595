module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5CR8XL4","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{}},"routeChangeEventName":"route-changed","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Weave","short_name":"Weave","start_url":"/","background_color":"#0287C3","theme_color":"#4b5255","display":"minimal-ui","icon":"src/images/weave-color.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f1d1f2888f60e42c24551811920a73ab"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
