exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-weave-js": () => import("./../../../src/pages/about-weave.js" /* webpackChunkName: "component---src-pages-about-weave-js" */),
  "component---src-pages-cannabis-online-ordering-js": () => import("./../../../src/pages/cannabis-online-ordering.js" /* webpackChunkName: "component---src-pages-cannabis-online-ordering-js" */),
  "component---src-pages-dispensary-analytics-js": () => import("./../../../src/pages/dispensary-analytics.js" /* webpackChunkName: "component---src-pages-dispensary-analytics-js" */),
  "component---src-pages-dispensary-inventory-and-compliance-js": () => import("./../../../src/pages/dispensary-inventory-and-compliance.js" /* webpackChunkName: "component---src-pages-dispensary-inventory-and-compliance-js" */),
  "component---src-pages-dispensary-loyalty-program-js": () => import("./../../../src/pages/dispensary-loyalty-program.js" /* webpackChunkName: "component---src-pages-dispensary-loyalty-program-js" */),
  "component---src-pages-dispensary-point-of-sale-js": () => import("./../../../src/pages/dispensary-point-of-sale.js" /* webpackChunkName: "component---src-pages-dispensary-point-of-sale-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-weave-support-js": () => import("./../../../src/pages/weave-support.js" /* webpackChunkName: "component---src-pages-weave-support-js" */)
}

